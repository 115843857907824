import { elementController } from '@public-pages/runtime-core';

elementController('modal-app-qr-code', ({ query, queryAll }) => {
  const modal = query('#modal-app-qr-code');
  const backdrop = query('#modal-app-qr-code-backdrop');
  const closeButton = queryAll('.close-modal');

  const isDesktop = () => window.innerWidth > 768;

  const openModal = () => {
    if (isDesktop()) {
      modal?.classList.add('modal-app-qr-code__container--on');
      backdrop?.classList.add('modal-app-qr-code__backdrop--on');
    }
    document.removeEventListener('DOMContentLoaded', openModal);
  };

  const closeModal = () => {
    modal?.classList.remove('modal-app-qr-code__container--on');
    backdrop?.classList.remove('modal-app-qr-code__backdrop--on');

    backdrop?.removeEventListener('click', closeModal);
    closeButton.forEach((button) =>
      button.removeEventListener('click', closeModal)
    );
  };

  backdrop?.addEventListener('click', closeModal);
  closeButton.forEach((button) => button.addEventListener('click', closeModal));
  document.addEventListener('DOMContentLoaded', openModal);
});
